import { useState, Fragment } from "react";
import Token from "../../../Database/Token";
import { addNotification } from "../../Notification/Notification";
import StreamIcon from "@mui/icons-material/Stream";
import { Tooltip, IconButton } from "@mui/material";

import Button from "../../Others/Button";

export default function StatusBooking(props) {
  const [active, setActive] = useState(false);

  const callFunction = async (status) => {
    setActive(false);
    props.onloading(props.id);
    try {
      const response = await Token.get(
        `/booking/all/changeStatus?id=${props.id}&status=${status}`
      );
      console.log(response);
      if (response.data.status) {
        addNotification(
          "Successful update",
          "your booking has been updated",
          "success"
        );
      } else {
        addNotification("Failed", response.data.message, "danger");
      }
    } catch (e) {
      addNotification("Failed", e.message, "error");
    }
    props.onUpdate();
  };
  return (
    <Fragment>
      <div className="text-white bg-red-500 p-2 rounded-md shadow-md hover:bg-red-600 ">
        <Tooltip arrow placement="top" title="Status">
          <IconButton className="w-6 h-6 " onClick={() => setActive(!active)}>
            <StreamIcon className="text-white" />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <h1 className="text-2xl font-bold text-red-500">Warnining</h1>
          <p className="my-3 text-lg">Do you want to change Status?</p>
          <Button
            color="red"
            title="Absent"
            onClick={() => callFunction("rejected")}
          />
          <Button
            color="green"
            title="Confirmed"
            onClick={() => callFunction("confirmed")}
          />
          <button
            className="w-full h-12 text-red-500 border-[1.5px] border-red-500 border-dotted my-3 rounded-md shadow-md hover:bg-red-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
