import React from "react";
import { useState, Fragment } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import Token from "../../Database/Token";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip, IconButton } from "@mui/material";
import Export from "../../Components/Others/Export";

export default function UserTripDetails(props) {
  const [isActive, setIsActive] = useState(false);
  const [data, setData] = useState([]);
  const [tableData, settableData] = useState([]);

  const demoFunction = async (e) => {
    setIsActive(e);
    try {
      console.log(props.baseApi);
      const response = await Token.get(
        `${props.baseApi}?plan_id=${props.planId}`
      );
      settableData(response.data);
      console.log(tableData)
      setData(response.data);
      console.log(data)
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    }
  };
  return (
    <Fragment>
      <div className="h-10 w-10 rounded-md shadow-md text-orange hover:bg-[#df6107] bg-[#f97316] outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500">
        <Tooltip arrow placement="top" title="Details">
          <IconButton onClick={() => demoFunction(true)}>
            <InfoIcon className="text-white" />
          </IconButton>
        </Tooltip>
      </div>

      {isActive === true && (
        <div
          className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 lg:p-1  bg-black/50`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="bg-white max-h-[600px] h-[600px] w-[1000px] lg:w-[600px] lg:h-[500px] md:w-[500px] md:-h-[500px] sm:w-[350px] sm:h-[500px] rounded-md overflow-x-scroll "
            key={tableData}
          >
            <button
              className="w-[200px] h-10 sm:w-[180px] rounded-sm my-2 mx-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
              onClick={() => setIsActive(false)}
            >
              Cancel
            </button>
            <Export data={data} />

            <input
              type="text"
              className="h-10 max-w-60 w-60 rounded-md border-[1.5px] border-gray-300 outline-none  p-2 focus:border-orange-500 float-right m-3"
            />
            <table className="min-w-full h-[500px]">
              <thead className="text-xs text-gray-100 uppercase bg-orange-500 text-center">
                <tr className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]">
                  <th className="text-left pl-1">S/N</th>
                  <th className="text-left pl-1">Name</th>
                  <th className="text-left pl-1">Phone</th>
                  <th className="text-left pl-1">Age</th>
                  <th className="text-left pl-1">Gender</th>
                  <th className="text-left pl-1">Pickup</th>
                  <th className="text-left pl-1">Group Name</th>
                  <th className="text-left pl-1">Seat</th>
                  <th className="text-left pl-1">Due amount</th>
                  <th className="text-left pl-1 max-w-[350px] w-[350px]">
                    Remark
                  </th>
                  <th className="text-left pl-1">Status</th>
                </tr>
              </thead>
              <tbody className="overflow-y-auto">
                {data.length === 0 ? (
                  <tr>
                    <td
                      colSpan={11}
                      className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                    >
                      Data not found
                    </td>
                  </tr>
                ) : (
                  data.map((list) => {
                    return (
                      <tr
                        className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                        key={list.SN}
                      >
                        {/* <th
                        className="px-1 py-4 md:px-4 sm:px-2 font-medium"
                        scope="row"
                      >
                        {list.sn}
                      </th> */}
                      <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.SN}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.name}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.phone}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.age}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.gender}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.pickup}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.group_name}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.seat}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.due_amount}
                        </td>
                        <td className="px-1 md:px-4 sm:px-2 py-4">
                          {list.remark}
                        </td>
                        <td>
                          <p
                            className={`cursor-pointer text-center rounded-md uppercase shadow-md p-[2px] ${
                              list.status === "confirmed"
                                ? "bg-green-500 text-green-100"
                                : list.status === "pending"
                                ? "bg-yellow-400 text-yellow-700"
                                : ""
                            }`}
                          >
                            {list.status}
                          </p>
                        </td>
                        {/* <td className="px-1 md:px-4 sm:px-2 py-4">
                        <StatusBooking
                          id={list.id}
                          onloading={() => demoFunction(true)}
                          onUpdate={() => demoFunction(true)}
                        />
                      </td> */}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </Fragment>
  );
}
