import axios from "axios";

const Axios = axios.create({
  // baseURL: 'http://localhost:8000/api',
  baseURL: "https://backend.thejunoon.in/api",

  headers: {
    "Content-Type": "application/json",
  },
});

export default Axios;
