import { useState, useEffect } from "react";

import UserSidebarNavbar from "./UserSidebarNavbar";
import Table from "../../Components/Table/Table";
import Search from "../../Components/Search/Search";
import Token from "../../Database/Token";
import Th from "../../Components/Table/Th";
import Moment from "react-moment";
import UserTripDetails from "./userTripDetails";

import { useAppContext } from "../../Database/Context";
import { addNotification } from "../../Components/Notification/Notification";

export default function UserTrip(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  // const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();
  const { sideBarOn } = props;
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    // setLoading(true)
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/plan/all/showPlan?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}`
        );
        if (response.data.status) {
          setTabalData(response.data.data);
          setData(Object.values(response.data.data.data));
        } else {
          addNotification("error", "message", "error");
        }
      } catch (e) {
        console.log(e);
        addNotification("error", e.message, "danger");
      }
      // setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar sideBarOn={sideBarOn}>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>
          <Table
            button={""}
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
            data={data}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                <Th
                  title="id"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  id
                </Th>
                <Th
                  title="name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Name
                </Th>
                <Th
                  title="left_person"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Left Booking
                </Th>
                <Th
                  title="deposit_amount"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  deposit
                </Th>
                <Th
                  title="remaining_amount"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  remaining amount
                </Th>
                <Th
                  title="remark"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  remark
                </Th>
                <Th
                  title="booking_commission"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  commission
                </Th>
                <Th
                  title="arrival_date"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  arrival date
                </Th>
                <Th
                  title="status"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  status
                </Th>
                <th
                  scope="col"
                  colSpan={1}
                  className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                >
                  action
                </th>
              </tr>
            </thead>

            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.map((list) => (
                <tr
                  className="bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px] "
                  key={list.id}
                >
                  <td className="w-4 px-2">{list.id}</td>
                  <th className="py-4 px-2 font-medium" scope="row">
                    {list.name} <br /> {list.formatted_departure_date}
                  </th>
                  <td className="px-2 py-4">{list.left_person}</td>
                  <td className="px-2 py-4">{list.deposit_amount}</td>
                  <td className="px-2 py-4">{list.remaining_amount}</td>
                  <td className="px-2 py-4">{list.remark}</td>
                  <td className="px-2 py-4">{list.booking_commission}₹</td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    <Moment format="DD MMM YYYY">{list.arrival_date}</Moment>
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    <span
                      className={`${
                        list.status === "completed"
                          ? "bg-green-700 text-green-300"
                          : list.status === "cancelled"
                          ? "bg-red-600 text-red-100"
                          : list.status === "started"
                          ? "bg-blue-600 text-blue-200"
                          : "bg-yellow-600 text-yellow-100 "
                      } text-xs font-medium me-2 px-2.5 py-1 uppercase cursor-pointer rounded`}
                    >
                      {list.status}
                    </span>
                  </td>
                  <td className="px-2 py-4 grid grid-cols-[40px_40px_40px_40px] gap-1">
                    <UserTripDetails
                      planId={list.id}
                      baseApi="/booking/user/getBookingsByPlanAndUser"
                      onUpdate={() => setUpdate(!update)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
