import React from "react";
import UserSidebarNavbar from "../User/UserSidebarNavbar";
import DashboardCard from "../../Components/Others/DashboardCard";
import Graph from "../../Components/Others/Graph";


export default function UserHome(props) {
  const { sideBarOn } = props;
  return (
    <UserSidebarNavbar sideBarOn={sideBarOn}>
      <div className="max-h-[calc(100%-80px)] w-full p-4 overflow-y-auto">
        <div className="gap-4 grid grid-cols-4 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
          <DashboardCard
            route="/Admin/booking"
            title="Booking"
            color="bg-[#2982ea]"
            api="/booking/Admin/countAdminBooking"
          />
          <DashboardCard
            route="/admin/payment"
            title="Commission"
            color="bg-[#5cb360]"
            api="/Commission/Admin/updateCommission"
          />
          <DashboardCard
            route="/Admin/trip"
            title="Trip"
            color="bg-[#fd980f]"
            api="/plan/all/TotalCount"
          />
          <DashboardCard
            route="/admin/payment"
            title="Payment"
            color="bg-[#e8403e]"
            api="/payment/all/TotalCount"
          />
        </div>
        <div className="md:grid-cols-1 gap-4 md:gap-1 sm:grid-cols-1 grid grid-cols-2">
          <Graph title="Booking Graph" api="/booking/Admin/getGraphAdmin"  />
          <Graph title="Commission Graph" api="/Commission/Admin/getGraphAdmin"  />
        </div>
        <div className="grid-cols-3 grid "></div>
      </div>
    </UserSidebarNavbar>
  );
}
