import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import Spin from "../../Assets/SVG/Spin";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function UpdateBooking(props) {
  const [employee, setEmployee] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState(props.phone);
  const [dob, setDob] = useState(props.dob);
  const [discount, setDiscount] = useState(props.discount);
  const [addPrice, setAddPrice] = useState(props.add_price);
  const [gender, setGender] = useState(props.gender);
  const [seatNo, setSeatNo] = useState(props.seatNo);
  const [pickup, setPickup] = useState(props.pickup);
  const [remark, setRemark] = useState(props.remark); 
  const [group, setGroup] = useState(props.group);
  const [hometown, setHometown] = useState(props.hometown);

  const AddBookingFunction = async () => {
    setLoading(true);

    const jsonData = {
      id: props.id,
      name: name,
      phone: phone,
      dob: dob,
      discount: discount,
      add_price: addPrice,
      gender: gender,
      seat: seatNo,
      pickup: pickup,
      remark: remark,
      hometown: hometown,
      group_name: group,
    };
    console.log('json data')
    console.log(jsonData)
    try {
      const response = await Token.post(`${props.baseApi}`, jsonData);

      if (response.data.status) {
        addNotification(
          "Successful booking",
          "Your booking has been updated",
          "success"
        );
        setEmployee(false);
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
    }
    props.update();
  };

  const allemployee = async () => {
    if (!name) {
      addNotification("Error", "Please enter Name", "danger");
      return;
    }

    if (!phone) {
      addNotification("Error", "Please enter total person", "danger");
      return;
    }

    AddBookingFunction();
  };

  return (
    <Fragment>
      <div className="h-10 w-10 rounded-md shadow-md text-white hover:bg-yellow-500 bg-yellow-400 outline-none focus-visible:ring-2 ring-offset-2 ring-yellow-500 mx-2">
        <Tooltip arrow placement="top" title="Edit">
          <IconButton className="" onClick={() => setEmployee(true)}>
            <EditIcon className="text-white mx-auto my-auto" />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 lg:p-1 ${
          employee ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[700px] lg:w-[600px] lg:h-[500px] md:w-[500px] md:h-[500px] sm:w-[350px] sm:h-[500px] rounded-md shadow-2xl transition-all p-5  ${
            employee ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold uppercase">
            Update trip details
          </p>
          <div className="grid grid-cols-2 gap-3 md:grid-cols-4 md:grid-flow-row sm:grid-cols-1 sm:gap-2 sm:m-5">
            <div className="md:col-span-2">
              <p className="my-1">Name</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Enter name"
              />
              <p className="my-1">Phone</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter remaining amount"
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
              />
              <p className="my-1">Gender</p>

              <select
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option value="" disabled>
                  Select Gender
                </option>
                <option
                  defaultChecked={gender === "male"}
                  onClick={() => setGender("male")}
                  value="male"
                >
                  Male
                </option>
                <option
                  defaultChecked={gender === "female"}
                  onClick={() => setGender("female")}
                  value="female"
                >
                  Female
                </option>
              </select>
              
              <p className="my-1">Pickup</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="e.g 600"
                onChange={(e) => {
                  setPickup(e.target.value);
                }}
                value={pickup}
              />
              <p className="my-1">Group Name</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="e.g 600"
                onChange={(e) => {
                  setGroup(e.target.value);
                }}
                value={group}
              />
            </div>

            <div className="md:col-span-2">
              <p className="my-1">Date of birth</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="date"
                placeholder="Enter departure date"
                onChange={(e) => setDob(e.target.value)}
                value={dob}
              />
              <p className="my-1">Discount</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter Total Person"
                onChange={(e) => {
                  setDiscount(e.target.value);
                }}
                value={discount}
              />

              <p className="my-1">Add Price</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="e.g 600"
                onChange={(e) => {
                  setAddPrice(e.target.value);
                }}
                value={addPrice}
              />
              <p className="my-1">Seat No</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="e.g 600"
                onChange={(e) => {
                  setSeatNo(e.target.value);
                }}
                value={seatNo}
              />
              <p className="my-1">Hometown</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="e.g 600"
                onChange={(e) => {
                  setHometown(e.target.value);
                }}
                value={hometown}
              />
            </div>
          </div>
          <p className="mt-1">Remark</p>

          <textarea
            className="w-full border-gray-300 border-[1.5px] shadow-md rounded-sm outline-none focus:border-blue-600 p-1"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
          ></textarea>

          {!loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600"
              onClick={allemployee}
            >
              Add
            </button>
          )}
          {loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              disabled
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 sm:w-[275px] sm:mx-5 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setEmployee(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
