import { Fragment, useEffect, useState } from "react";
import Token from "../../Database/Token";
import Moment from "react-moment";

export default function Select(props) {
  const [active, setActive] = useState(false);
  const [title, setTitle] = useState(props.title);
  const [data, setData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  useEffect(() => {
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `${props.api}`
        );
        console.log(response);
        if (response.data.status) {
          setData(Object.values(response.data.data.data));
          setSearchData(Object.values(response.data.data.data));
          console.log(response.data.data.data);
        } else {
          console.log(response.data.data.data);
        }
      } catch (e) {
        console.log(e.message);
      }
    };
    functionCall();
  }, []);

  const callFunction = (e) => {
    const search = searchData.filter((item) =>
      item.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    console.log(search);
    setData(search);
  };

  return (
    <Fragment>
      <div
        className="max-w-[800px] w-full h-10 border-[1px] border-[#b3b3b3] rounded-md shadow-sm flex p-[6px] font-normal cursor-pointer"
        onClick={() => setActive(!active)}
      >
        {title}
      </div>
      <div
        className={`p-3 border-[1px] border-[#b3b3b3] transition-all max-w-[calc(100%-24px)] mt-2 rounded-md w-full absolute max-h-[600px] bg-white shadow-2xl ${
          active
            ? "scale-[1.02] opacity-100 pointer-events-auto"
            : "scale-75 opacity-0 pointer-events-none"
        }
        `}
      >
        <input
          type="text"
          className="w-full h-10 rounded-md py-1 px-2 border-[1.7px] border-[#b3b3b3] focus:border-blue-600 outline-none"
          placeholder="Search..."
          onChange={callFunction}
        />
        <div className="mt-1 max-h-[250px] overflow-y-auto scroll-bar">
          {data.map((list) => (
            <li
              key={list.id}
              className="list-none p-2 hover:bg-[#f2f2f2] rounded-md cursor-pointer"
              onClick={() => {
                setTitle(`${list.name} ${list.formatted_departure_date}`);
                setActive(false);
                if (props.type === "plan") {
                  props.deposit(list.deposit_amount);
                  props.reamount(list.remaining_amount);
                } else if (props.type === "state") {
                  props.name(list.state);
                } else if (props.type === "user") {
                  props.name(list.name);
                }
                props.id(list.id);
              }}
            >
              {list.name}
              {props.type === "plan" ? (
                <Moment format="  DD MMM">{list.departure_date}</Moment>
              ) : (
                ""
              )}
            </li>
          ))}
          {data.length === 0 && (
            <p
              className="text-center text-gray-500 hover:bg-[#f2f2f2]"
              onClick={() => setActive(false)}
            >
              No Data Found
            </p>
          )}
        </div>
      </div>
    </Fragment>
  );
}
