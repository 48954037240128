import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import Token from "../../Database/Token";


const Graph = (props) => {
  const [data, setData] = useState([]);
  const [xData, setXData] = useState([]);

  useEffect(() => {
    const callFunction = async ()=>{
      const response = await Token.get(props.api)
      .then(response => {
        const bookings = response.data;
        const dataPoints = bookings.map(entry => entry.count);
        const xPoints = bookings.map(entry => entry.month);

        setData(dataPoints);
        setXData(xPoints);
      })
      .catch(error => {
        console.error("There was an error fetching the data!", error);
      });
    }
    callFunction();
    
  }, []);

  const options = {
    title: "Monthly Bookings",
  };

  return (
    <div className="bg-white mt-4 p-4 md:p-1 sm:p-1 rounded-md shadow-md" key={data}>
      <p className="text-center">{props.title}</p>
      <Stack sx={{ width: "100%" }}>
        <LineChart
          xAxis={[{ data: xData, scaleType: "point" }]}
          series={[{ data, connectNulls: true }]}
          height={200}
          margin={{ top: 10, bottom: 20 }}
          options={options}
        />
      </Stack>
    </div>
  );
};

export default Graph;
