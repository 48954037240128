import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import Spin from "../../Assets/SVG/Spin";

export default function AddUser(props) {
  const [employee, setEmployee] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState(0);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");

  const maxPhoneLength = 10;

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (value.length <= maxPhoneLength) {
      setPhone(value);
    }
  };

  const AddBookingFunction = async () => {
    setLoading(true);

    const d = new Date(dob);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const newDob = `${year}/${month}/${day}`;

    const jsonData = {
      name,
      email,
      phone,
      gender,
      dob: newDob,
      type: props.chkType,
    };

    console.log(jsonData);

    try {
      const response = await Token.post(props.baseAPI, jsonData);
      console.log("Response:", response);

      if (response.data.status) {
        addNotification(
          "Successful booking",
          "New employee has been created check the email address",
          "success"
        );
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
    }
  };

  const allEmployee = async () => {
    if (!name) {
      addNotification("Error", "Please enter Name", "danger");
      return;
    }
    if (!email) {
      addNotification("Error", "Please enter email", "danger");
      return;
    }
    if (!phone) {
      addNotification("Error", "Please enter phone number", "danger");
      return;
    }

    AddBookingFunction();
  };

  return (
    <Fragment>
      <button
        className="w-40 m-3 rounded-md shadow-md text-white h-10 hover:bg-[#df6107] bg-[#f97316] outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
        onClick={() => setEmployee(!employee)}
      >
        + {props.chkType === "user" ? props.btnTitle1 : props.btnTitle2}
      </button>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          employee ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            employee ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold">
           Create Employee
          </p>

          <p className="mt-1">Name</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            placeholder="Enter name"
          />

          <p className="mt-1">Email</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="email"
            placeholder="Enter Email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <p className="mt-1">Phone</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="number"
            placeholder="Enter Phone"
            onChange={handlePhoneChange}
            value={phone}
          />
          <p className="mt-1">Gender</p>
          <select
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            onChange={(e) => setGender(e.target.value)}
            value={gender}
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          <p className="mt-1">Date Of Birth</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="date"
            placeholder="Enter your valid date of birth"
            onChange={(e) => setDob(e.target.value)}
            value={dob}
          />

          {!loading && (
            <button
              className="w-full h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600"
              onClick={allEmployee}
            >
              Add
            </button>
          )}
          {loading && (
            <button
              className="w-full h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              disabled
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setEmployee(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
