import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../../Database/Axios";

import Spin from "../../Assets/SVG/Spin";
import Eye from "../../Assets/SVG/Eye";
import EyeSlash from "../../Assets/SVG/EyeSlash";

import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Store } from "react-notifications-component";

export default function ForgotPassword() {
  const location = useLocation();
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [eye1, setEye1] = useState(false);
  const [eye2, setEye2] = useState(false);

  
  //     php artisan serve --host=172.27.240.1 --port=8000
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEmail(searchParams.get("email"));
    setToken(searchParams.get("token"));
    // if(token === "" || email === "")
    // {
    //   history("/login");
    // }
  }, [location.search]);

  const notifications = (title, message, type) => {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const loginFunction = async () => {
    if (password === "") {
      notifications(
        "fild is empty",
        "you need to enter valid password",
        "danger"
      );
      return 0;
    }
    if (conformPassword === "") {
      notifications(
        "fild is empty",
        "you need to enter valid confirm password",
        "danger"
      );
      return 0;
    }
    if (password !== conformPassword) {
      notifications(
        "password is not same",
        "you need to enter same password in filds ",
        "danger"
      );
      return 0;
    }

    setLoading(true);
    const data = {
      token: token,
      password: password,
      email: email,
    };
    try {
      console.log(data);
      const response = await Axios.post("/user/all/forgot-password", data);
      console.log(response.data);
      if (response.data.status) {
        notifications(`Send`, "Your password has been change", "success");
      } else {
        notifications(`Wrong`, response.data.message, "danger");
      }
    } catch (e) {
      notifications(`Error`, e.message, "success");
    }
    setLoading(false);
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex justify-center ">
          <img src={Image} alt="not found" className="w-[200px]" />
        </div>
        <h1 className="text-center my-2 text-4xl font-semibold text-orange-500">
          Enter your Password!
        </h1>
        <p className="text-center">
          after you enter the password and conform password you'l edirect to
          login page,
        </p>
        <div className="h-1"></div>
        <label>Enter Password</label>
        <div className="group">
          <input
            type={eye1 ? "text" : "password"}
            className="w-[calc(100%-40px)] h-10 rounded-s-sm border-orange-300 border-[1.5px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left border-r-0"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Enter password"
          />
          <div
            className="float-left w-10 h-10 border-[1.5px] border-l-0 border-orange-300 flex items-center justify-center hover:border-orange-500 hover:border-l-[1.5px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye1(!eye1)}
          >
            {eye1 && <Eye />}
            {!eye1 && <EyeSlash />}
          </div>
        </div>
        <div className="h-2"></div>
        <label>Conform Password</label>
        <div className="group">
          <input
            type={eye2 ? "text" : "password"}
            className="w-[calc(100%-40px)] h-10 rounded-s-sm border-orange-300 border-[1.5px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left border-r-0"
            onChange={(e) => setConformPassword(e.target.value)}
            value={conformPassword}
            placeholder="Enter password"
          />
          <div
            className="float-left w-10 h-10 border-[1.5px] border-l-0 border-orange-300 flex items-center justify-center hover:border-orange-500 hover:border-l-[1.5px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye2(!eye2)}
          >
            {eye2 && <Eye />}
            {!eye2 && <EyeSlash />}
          </div>
        </div>

        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>

        <br />
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}

