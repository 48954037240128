import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import Spin from "../../Assets/SVG/Spin";

export default function AddTrip(props) {
  const [employee, setEmployee] = useState(props.id);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(props.name.name);
  const [remainingAmt, setRemainingAmt] = useState(props.name.remaining_amount);
  const [totalPerson, setTotalPerson] = useState(props.name.total_person);
  const [remark, setRemark] = useState(props.name.remark);
  const [bookingComm, setBookingComm] = useState(props.name.booking_commission);
  const [deptDate, setDeptDate] = useState(props.name.departure_date);
  const [arrDate, setArrDate] = useState(props.name.arrival_date);
  const [depositAmt, setDepositAmt] = useState(props.name.deposit_amount);

  
  const AddBookingFunction = async () => {
    setLoading(true);

    const jsonData = {
      name: name,
      remaining_amount: remainingAmt,
      total_person: totalPerson,
      remark: remark,
      booking_commission: bookingComm,
      departure_date: deptDate,
      arrival_date: arrDate,
      deposit_amount: depositAmt,
    };

    console.log("Sending JSON data:", JSON.stringify(jsonData, null, 2));

    try {
      const response = await Token.get(
        `${props.baseAPI}?name=${name}&remaining_amount=${remainingAmt}&total_person=${totalPerson}&remark=${remark}&booking_commission=${bookingComm}&departure_date=${deptDate}&arrival_date=${arrDate}&deposit_amount=${depositAmt}`
      );

      
      console.log("Response:", response);

      if (response.data.status) {
        addNotification(
          "Successful booking",
          "Your booking has been created",
          "success"
        );
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
    }
    props.update();
  };

  const allemployee = async () => {
    if (!name) {
      addNotification("Error", "Please enter Name", "danger");
      return;
    }
    if (!remainingAmt) {
      addNotification("Error", "Please enter remaining amount", "danger");
      return;
    }
    if (!totalPerson) {
      addNotification("Error", "Please enter total person", "danger");
      return;
    }

    AddBookingFunction();
  };

  return (
    <Fragment>
      <button
        className="w-40 m-3 rounded-md shadow-md text-white h-10 hover:bg-[#df6107] bg-[#f97316] outline-none focus-visible:ring-2 ring-offset-2 ring-orange-500"
        onClick={() => setEmployee(!employee)}
      >
        + {props.btnTitle}
      </button>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 lg:p-1 ${
          employee ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[700px]  h-[630px] lg:w-[600px] lg:h-[500px] md:w-[500px] md:h-[500px] sm:w-[350px] sm:h-[500px] overflow-y-scroll rounded-md shadow-2xl transition-all p-5  ${
            employee ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* <Scroll height="500px" /> */}
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold">
            Enter trip details
          </p>
          <div className="grid grid-cols-2 gap-8 md:grid-cols-4 md:grid-flow-row sm:grid-cols-1 sm:gap-2 sm:m-5">
            <div className="md:col-span-2">
              <p className="my-1">Name</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Enter name"
              />
              <p className="my-1">Deposit amount</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="e.g 600"
                onChange={(e) => {
                  setDepositAmt(e.target.value);
                }}
                value={depositAmt}
              />
              <p className="my-1">Remaining amount</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter remaining amount"
                onChange={(e) => setRemainingAmt(e.target.value)}
                value={remainingAmt}
              />

              <p className="my-1">Total Person</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter Total Person"
                onChange={(e) => {
                  setTotalPerson(e.target.value);
                }}
                value={totalPerson}
              />
            </div>

            <div className="md:col-span-2">
              <p className="my-1">Booking commission</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="e.g 600"
                onChange={(e) => {
                  setBookingComm(e.target.value);
                }}
                value={bookingComm}
              />
              <p className="my-1">Departure date</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="date"
                placeholder="Enter departure date"
                onChange={(e) => setDeptDate(e.target.value)}
                value={deptDate}
              />

              <p className="my-1">Arrival date</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="date"
                placeholder="Enter arrival date"
                onChange={(e) => setArrDate(e.target.value)}
                value={arrDate}
              />
            </div>
          </div>
          <p className="my-1 sm:mx-5">Remark</p>
          <textarea
            className="h-[100px] sm:mx-5 sm:w-[275px] border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all  w-full"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
            type="text"
            placeholder="Enter remark"
          />

          {!loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600"
              onClick={allemployee}
            >
              Add
            </button>
          )}
          {loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              disabled
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 sm:w-[275px] sm:mx-5 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setEmployee(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
