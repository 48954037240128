import { useState } from "react";
import Arrow from "../../Assets/SVG/Arrow";

export default function Th(props) {
  const [arrow, setArrow] = useState("none");
  const [dot, setDot] = useState(true);

  const changeClick = () => {
    if (arrow === "none") {
      setArrow("down");
      props.setFunction("asc", props.title);
    } else if (arrow === "down") {
      setArrow("up");
      props.setFunction("desc", props.title);
    } else if (arrow === "up") {
      setArrow("none");
      props.setFunction("desc", "");

    }
  };

  const setFunction = (e) => {
    props.setFunction(e, props.title);
  };
  return (
    <th
      scope="col"
      colSpan={1}
      className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
    >
      <p
        className={`group-hover:hidden ${
          dot ? "block" : "hidden"
        } group-hover:text-gray-200 float-left my-2`}
      >
        {props.children}
      </p>{" "}
      <div
        className={`duration-300 my-1 ${
          !(arrow === "none")
            ? "block "
            : "group-hover:block hidden fill-gray-200"
        } float-left ${
          arrow === "up" ? "rotate-180" : ""
        } hover:bg-orange-300/50 rounded-full fill-white`}
        onClick={changeClick}
      >
        <Arrow />
      </div>
      {/* <div
        className={`p-1 scale-[0.70] duration-300 relative float-right ${
          !dot ? "block " : "group-hover:block hidden fill-gray-200"
        } float-right hover:bg-orange-300/50 rounded-full fill-white`}
        onClick={() => setDot(!dot)}
      >
        <ThreeDot />
        <div
          className={`py-2 duration-300 w-64 bg-white rounded-md shadow-md border-[1px] border-gray-300 absolute ${
            !dot ? "opacity-100 scale-100 pointer-events-auto" : "opacity-0 scale-75 pointer-events-none"
          }`}
        >
          <div
            className=" h-14 hover:bg-[#f5f5f5] bg-red text-black"
            onClick={() => setFunction("desc")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="45"
              height="45"
              fill="currentColor"
              className="bi bi-arrow-up-short m-2 float-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"
              />
            </svg>
          </div>
          <div
            className="px-3 h-14 hover:bg-[#f5f5f5]"
            onClick={() => setFunction("asc")}
          ></div>
          <div
            className="px-3 h-14 hover:bg-[#f5f5f5] border-b-[1px] border-gray-400"
            onClick={() => setFunction("")}
          ></div>
          <div
            className="px-3 h-14 hover:bg-[#f5f5f5]"
            onClick={() => setFunction("")}
          ></div>
          <div
            className="px-3 h-14 hover:bg-[#f5f5f5]"
            onClick={() => setFunction("")}
          ></div>
        </div>
      </div> */}
    </th>
  );
}
