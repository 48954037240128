import { useState, Fragment } from "react";
import Token from "../../../Database/Token";
import { addNotification } from "../../Notification/Notification";
import StreamIcon from "@mui/icons-material/Stream";
import { Tooltip, IconButton } from "@mui/material";

export default function Rejecte(props) {
  const [active, setActive] = useState(false);

  const callFunction = async () => {
    setActive(false);
    props.onloading(props.id);
    try {
      const response = await Token.get(
        `/booking/user/updateBooking?id=${props.id}`
      );
      if (response.data.status) {
        addNotification(
          "Successful update",
          "your booking has been updated",
          "success"
        );
      } else {
        addNotification("Failed", "Something went wrong", "error");
      }
    } catch (e) {
      addNotification("Failed", e.message, "error");
    }
    props.onUpdate();
  };
  return (
    <Fragment>
      <div className="w-10 h-10 shadow-md text-white bg-red-500 rounded-md hover:bg-red-600 outline-none focus-visible:ring-2 focus-visible:ring-red-600 focus-visible:ring-offset-2">
        <Tooltip arrow placement="top" title="Status">
          <IconButton className="" onClick={() => setActive(!active)}>
            <StreamIcon className="text-white mx-auto my-auto " />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="my-3 text-lg">
            Are you sure you want to cancel this booking ?
          </p>
          <button
            className="w-full h-12 bg-red-500 hover:bg-red-600 text-white text-xl rounded-md shadow-md"
            onClick={callFunction}
          >
            Sure
          </button>
          <button
            className="w-full h-12 text-red-500 border-[1.5px] border-red-500 border-dotted my-3 rounded-md shadow-md hover:bg-red-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
