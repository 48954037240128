import { useState, useEffect } from "react";

import UserSidebarNavbar from "./UserSidebarNavbar";
import Table from "../../Components/Table/Table";
import Search from "../../Components/Search/Search";
import Token from "../../Database/Token";
import AddBooking from "../../Components/Pages/User/AddBooking";
import Rejecte from "../../Components/Pages/User/Rejecte";
import Loading from "../../Assets/SVG/Loading";
import Th from "../../Components/Table/Th";
import Status from "../../Components/Others/Status";
import UpdateBooking from "./UpdateBooking";

import { useAppContext } from "../../Database/Context";
import { addNotification } from "../../Components/Notification/Notification";

export default function UserHome(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);
  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/booking/user/showBooking?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}`
        );
        console.log(response);
        if (response.data.status) {
          setTabalData(response.data.data);
          console.log(TableData);
          setData(Object.values(response.data.data.data));
        } else {
          addNotification("error", response.data.message, "danger");
        }
      } catch (e) {
        console.log(e);
        addNotification("error  ", e.message, "danger");
      }
      setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>


          <Table
            button={<AddBooking update={() => setUpdate(!update)} />}
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
            data={data}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                <Th
                  title="id"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  id
                </Th>
                <Th
                  title="name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Name
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  phone
                </Th>
                <Th
                  title="gender"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  gender
                </Th>
                <Th
                  title="discount"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  discount
                </Th>
                <Th
                  title="add_price"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  add price
                </Th>
                <Th
                  title="group_name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  group name
                </Th>
                <Th
                  title="hometown"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  hometown
                </Th>
                <Th
                  title="remark"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  remark
                </Th>
                <Th
                  title="plans.name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  plan
                </Th>
                <Th
                  title="status"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  status
                </Th>
                <th
                  scope="col"
                  colSpan={1}
                  className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                >
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={12}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.map((list) => (
                <tr
                  className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                  key={list.id}
                >
                  <td className="w-4 px-2">{list.id}</td>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    {list.name}
                  </th>
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.phone}</td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.gender}</td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.discount}₹
                  </td>
                  <td className="px-4 sm:px-2 py-4">{list.add_price}</td>
                  <td className="px-4 sm:px-2 py-4">{list.group_name}</td>
                  <td className="px-4 sm:px-2 py-4">{list.hometown}</td>
                  <td className="px-4 sm:px-2 py-4 min-w-60">{list.remark}</td>
                  <td className="px-4 sm:px-2 py-4">{list.plan_name}</td>
                  <td className="px-4 sm:px-2 py-4">
                    <Status status={list.status} />
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4 grid grid-col-2 grid-cols-[50px_50px]">
                    {loading !== list.id && list.status !== "rejected" && (
                      <>
                        <Rejecte
                          className="col-span-1"
                          id={list.id}
                          onloading={(e) => setLoading(e)}
                          onUpdate={() => setUpdate(!update)}
                        />
                        <UpdateBooking
                          baseApi="/booking/Admin/updateBooking"
                          className="col-span-1"
                          id={list.id}
                          name={list.name}
                          email={list.email}
                          phone={list.phone}
                          dob={list.dob}
                          discount={list.discount}
                          add_price={list.add_price}
                          update={() => setUpdate(!update)}
                          gender={list.gender}
                          seatNo={list.seat}
                          remark={list.remark}
                          pickup={list.pickup}
                          group={list.group_name} 
                          hometown={list.hometown}
                        />
                      </>
                    )}

                    {list.status === "rejected" && (
                      <div>No Action</div>
                    )}
                    {loading === list.id && (
                      <div className="w-16 flex items-center justify-center">
                        <Loading />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
