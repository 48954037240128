export default function Status(props) {
  return (
    <span
      className={`${
        props.status === "confirmed"
          ? "bg-green-700 text-green-300"
          : props.status === "rejected"
          ? "bg-red-600 text-red-100"
          : "bg-yellow-600 text-yellow-100"
      } text-xs font-medium me-2 px-2.5 py-1 uppercase cursor-pointer rounded`}
    >
      {props.status}
    </span>
  );
}
