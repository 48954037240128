import { useState, Fragment, useEffect } from "react";
import Select from "../../Others/Select";
import Token from "../../../Database/Token";
import { addNotification } from "../../Notification/Notification";
import Spin from "../../../Assets/SVG/Spin";

export default function AddBooking(props) {
  const [active, setActive] = useState(false);
  const [payment, setPayment] = useState(false);
  const [amount, setAmount] = useState(0);
  const [reamount, setReamount] = useState(0);
  const [reamount1, setReamount1] = useState(0);
  const [deposit, setDeposit] = useState(0);
  const [person, setPerson] = useState(1);
  const [plan, setPlan] = useState(props.id);
  const [discount, setDiscount] = useState(0);
  const [addPrice, setAddPrice] = useState(0);
  const [reciverName, setReciverName] = useState("");
  const [paymentDate, setPaymentDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [planUpdated, setPlanUpdated] = useState(false);

  const [name, setName] = useState(props.name);
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [seat, setSeat] = useState("");
  const [hometown, setHometown] = useState("");
  const [groupName, setGroupName] = useState("");
  const [pickup, setPickup] = useState("");

  const [remark, setRemark] = useState("none");

  const [paymentType, setPaymentType] = useState("online");

  useEffect(() => {
    const totalAmount = deposit * person;
    setAmount(totalAmount.toLocaleString());
    const remaining_amount =
      reamount1 * person + addPrice * person - discount * person;
    setReamount(remaining_amount.toLocaleString());
  }, [person, deposit, discount, addPrice]);

  const AddBookingFunction = async () => {
    if (name == "") {
      addNotification("Error", "please enter name", "warning");
      return 0;
    }
    if (phone == "") {
      addNotification("Error", "please enter phone number", "warning");
      return 0;
    }
    if(dob == "") {
      addNotification("Error", "please enter date of birth", "warning");
      return 0;
    }
    if(gender == "") {
      addNotification("Error", "please enter gender", "warning");
      return 0;
    }
    if(seat == "") {
      addNotification("Error", "please enter seat number", "warning");
      return 0;
    }
    if(pickup == "") {
      addNotification("Error", "please enter pickup", "warning");
      return 0;
    }
    setLoading(true);

    const jsonData = {
      name: name,
      phone: phone,
      gender: gender,
      dob: dob,
      remark: remark,
      pickup,
      hometown,
      seat,
      group_name: groupName,
      plan_ref_id: plan,
      discount: discount,
      add_price: addPrice,
      deposit_id: paymentDate,
    };
    console.log(jsonData);
    try {
      const response = await Token.post("/booking/all/addBooking", jsonData);
      console.log(response);
      if (response.data.status) {
        addNotification(
          "Sucessful booking",
          "your booking has been created",
          "success"
        );
        setName("");
        setPhone("");
        setDob("");
        setGender("");
        setSeat("");
        setPickup("");

        if (person <= 1) {
          props.update();
          setActive(false);
          setPayment(0);
          setRemark("");
          setPlan("");
          setDiscount(0);
          setAddPrice(0);
          setPaymentDate("");
          setReciverName("");
          setDeposit("");
          setPaymentType("online");
          setHometown("");
          setGroupName("");
          setReciverName("");
          setPaymentDate("");
          setPlanUpdated(!planUpdated);
        } else {
          setPerson(person - 1);
        }
      } else {
        addNotification("Error", response.data.message, "danger");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      addNotification("Error", e.message, "danger");
    }
    setLoading(false);
  };

  const allPayment = async () => {
    if (deposit === 0 || deposit === "") {
      addNotification("Error", "please enter Plan", "danger");
      return 0;
    }
    if (person === "" || person === "") {
      addNotification("Error", "please enter  person", "danger");
      return 0;
    }
    if (paymentDate === "") {
      addNotification("Error", "please enter payment date", "danger");
      return 0;
    }
    if (hometown === "") {
      addNotification("Error", "please enter hometown", "danger");
      return 0;
    }
    if (paymentType === "") {
      addNotification("Error", "please enter payment type", "danger");
      return 0;
    }
    if (groupName === "") {
      addNotification("Error", "please enter group name", "danger");
      return 0;
    }
    if (reciverName === "") {
      addNotification("Error", "please enter receiver name", "danger");
      return 0;
    }

    setLoading(true);
    const jsonData = {
      amount: deposit * person,
      method: paymentType,
      reciver_name: reciverName,
      date: paymentDate,
      type: "deposit",
    };
    console.log(jsonData);
    try {
      const response = await Token.post("/payment/all/createPayment", jsonData);
      if (response.data.status) {
        addNotification(
          "Sucessful payment",
          "your payment has been created",
          "success"
        );
        setPaymentDate(response.data.data.id);
        console.log(response.data);
        setActive(true);
        setPayment(false);
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };
  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
  };

  return (
    <Fragment>
      <button
        className="w-32 m-3 rounded-md shadow-md text-white h-10 hover:Fbg-[#df6107] bg-[#f97316] "
        onClick={() => setPayment(!payment)}
      >
        + New Booking
      </button>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          payment ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[800px] w-[800px] rounded-md shadow-2xl transition-all p-3 ${
            payment ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold">
            Select Plan
          </p>
          <div className="grid grid-cols-2 gap-3">
            <div key={planUpdated}>
              <p className="mt-1">Select Plan</p>

              <Select
                title="Select Plan"
                deposit={(e) => setDeposit(e)}
                reamount={(e) => setReamount1(e)}
                id={(e) => setPlan(e)}
                api="/plan/all/showPlan?order=desc&title=departure_date&row=999999999"
                type="plan"
              />
              <p className="mt-1">Total Person</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                onChange={(e) => setPerson(e.target.value)}
                value={person}
                type="number"
                placeholder="Enter total person that going"
              />
              <p className="mt-1">Discount Per Person</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter Discount"
                onChange={(e) => setDiscount(e.target.value)}
                value={discount}
              />
              <p className="mt-1">Home Town</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="Enter Hometown"
                onChange={(e) => setHometown(e.target.value)}
                value={hometown}
              />
              <p className="mt-1">Group name</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="Enter Group Name"
                onChange={(e) => setGroupName(e.target.value)}
                value={groupName}
              />
              <p className="my-2">Total Deposit Amount: {amount}</p>
            </div>
            <div>
              <p className="mt-1">Payment Type</p>
              <select
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="dropdown"
                onChange={handlePaymentType}
                value={paymentType}
              >
                <option disabled>Select payment Type</option>
                <option value="online">Online</option>
                <option value="cash">Cash</option>
              </select>

              <p className="mt-1">Reciver Name</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="text"
                placeholder="Enter Reciver Name"
                onChange={(e) => setReciverName(e.target.value)}
                value={reciverName}
              />
              <p className="mt-1">Add Price Per Person</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="number"
                placeholder="Enter Discount"
                onChange={(e) => setAddPrice(e.target.value)}
                value={addPrice}
              />
              <p className="mt-1">Payment Date</p>
              <input
                className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
                type="date"
                placeholder="Enter Payment ID"
                onChange={(e) => setPaymentDate(e.target.value)}
                value={paymentDate}
              />
              <div className="h-20"></div>
              <p className="my-2">Total Remaing Amount: {reamount} </p>
            </div>
          </div>
          <p className="mt-1">Remark</p>

          <textarea
            className="w-full border-gray-300 border-[1.5px] shadow-md rounded-sm outline-none focus:border-blue-600 p-1"
            onChange={(e) => setRemark(e.target.value)}
            value={remark}
          ></textarea>

          {!loading && (
            <button
              className="w-full h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600"
              onClick={allPayment}
            >
              Add
            </button>
          )}
          {loading && (
            <button
              className="w-full h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              onClick={allPayment}
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setPayment(false)}
          >
            Cancel
          </button>
        </div>
      </div>
      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold">
            {person} left Booking
          </p>
          <p>Name</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <p>Contact No.</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="number"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <p>DOB</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="date"
            placeholder="Enter Payment ID"
            onChange={(e) => setDob(e.target.value)}
            value={dob}
          />
          {/* <input
            type="date"
            className="shadow-md rounded-md border-gray-300 border-[1.5px]"
            onChange={(e) => setDob(e.target.value)}
            value={dob}
          /> */}
          <p className="mt-1">Gender</p>
          <select
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            onChange={(e) => setGender(e.target.value)}
            value={gender}
          >
            <option value="" disabled>
              Select Gender
            </option>
            <option onClick={() => setGender("male")} value="male">
              Male
            </option>
            <option onClick={() => setGender("female")} value="female">
              Female
            </option>
          </select>
          <p>Pickup</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="text"
            onChange={(e) => setPickup(e.target.value)}
            value={pickup}
          />
          <p>Seat No.</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            type="text"
            onChange={(e) => setSeat(e.target.value)}
            value={seat}
          />
          {!loading && (
            <button
              className="w-full h-10 bg-orange-500 my-3 rounded-sm text-lg text-white focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-orange-500 outline-none"
              onClick={AddBookingFunction}
            >
              Add
            </button>
          )}
          {loading && (
            <button
              className="w-full h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              onClick={allPayment}
            >
              <Spin />
            </button>
          )}
          <button
            className="text-orange-500 w-full border-[1.5px] h-10 focus-visible:border-[1.7px] outline-none focus-visible:bg-orange-50 hover:bg-orange-50 rounded-sm border-orange-500 border-dashed"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
