import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../../Database/Axios";
import Spin from "../../Assets/SVG/Spin";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";

export default function ForgotPassword() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const notifications = (title, message, type) => {
    Store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };
  function isValidEmailFunction(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const loginFunction = async () => {
    if (email === "" && !isValidEmailFunction(email)) {
      notifications(
        "fild is empty",
        "you need to enter valid email address",
        "danger"
      );
      return 0;
    }

    try {
      setLoading(true);
      const response = await Axios.get(`/user/all/forgot-request?email=${email}`);
      setLoading(false);
      if (response.data.status) {
        notifications(`Send`, "Check you email box for link", "success");
      } else {
        notifications(`Send`, response.data.message, "danger");
      }
    } catch (e) {
      setLoading(false);
      notifications("Error", "Someting went wrong", "danger");
    }
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex justify-center ">
          <img src={Image} alt="not found" className="w-[200px]" />
        </div>
        <h1 className="text-center my-2 text-4xl font-semibold text-orange-500">
          Forgot Password?
        </h1>
        <p className="text-center">
          After the submit forgot password request you will recive the mail from
          our website.
        </p>
        <p>Email</p>
        <input
          type="text"
          className="w-full h-10  rounded-sm border-orange-300 border-[1.5px] outline-none px-2 focus-within:border-orange-500"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter Email"
        />
        <div className="h-2 mt-2">
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history(-1)}
          >
            Back
          </p>
        </div>
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2"
            onClick={loginFunction}
          >
            Submit
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full mt-2 text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 mb-2 flex items-center justify-center">
            <Spin />
          </button>
        )}
      </div>
    </div>
  );
}
