import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";
import StreamIcon from "@mui/icons-material/Stream";

export default function StatusChange(props) {
  const [active, setActive] = useState(false);
  const [change, setChange] = useState("");
  const statusChange = async (title) => {
    setActive(false);
    try {
      setChange(title);
      console.log(title);
      const response = await Token.get(
        `${props.api}?id=${props.id}&status=${title}`
      );
      console.log("Response : ", response);
      if (response.data.status) {
        props.onUpdate();
        setChange(response.data.status);
        addNotification(
          "Successful booking",
          "Your booking has been Updated",
          "success"
        );
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      addNotification("Failed", error.message, "error");
    }
  };

  return (
    <Fragment>
      <div className="text-white bg-red-500 w-10 h-10 flex items-center justify-center rounded-md shadow-md hover:bg-red-600 ">
        <Tooltip arrow placement="top" title="Status">
          <IconButton className="w-6 h-6" onClick={() => setActive(!active)}>
            <StreamIcon className="text-white" />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="my-3 text-lg">Do you want to change Status</p>
          <br />
          <button
            className="w-full h-12 bg-green-500 hover:bg-green-600 ring-offset-2 ring-green-500 outline-none focus-visible:ring-2 text-white text-xl rounded-md shadow-md"
            onClick={() => statusChange("confirmed")}
          >
            Confirm
          </button>
          <button
            className="outline-none focus-visible:ring-2 ring-offset-2 ring-red-500 mt-3 w-full h-12 bg-red-500 hover:bg-red-600 text-white text-xl rounded-md shadow-md"
            onClick={() => statusChange("rejected")}
          >
            Reject
          </button>
          <button
            className="w-full h-12 text-red-500 border-[1.5px] border-red-500 border-dotted my-3 rounded-md shadow-md hover:bg-red-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
