import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import List from "../../Assets/SVG/List";
import { Link } from "react-router-dom";
import LinkToTag from "./linkToTag";

import AccountBox from "@mui/icons-material/AccountBox";
import PaymentsIcon from "@mui/icons-material/Payments";
import PaidIcon from "@mui/icons-material/Paid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import BeenhereRoundedIcon from "@mui/icons-material/BeenhereRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import ManIcon from "@mui/icons-material/Man";
import TimeToLeaveIcon from "@mui/icons-material/TimeToLeave";
// import AccountBoxIcon from "@mui/icons-material/AccountBox";

const UserSidebarNavbar = (props) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullscreen = () => {
    const element = document.documentElement;
    const userAgent = window.navigator.userAgent;

    // Check if the browser is Safari
    const isSafari =
      userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1;

    if (!isSafari) {
      if (fullscreen) {
        document.exitFullscreen();
        setFullscreen(false);
      } else {
        element.requestFullscreen().catch((err) => {
          console.error("Error attempting to enable fullscreen:", err);
        });
        setFullscreen(true);
      }
    } else {
      console.log("Safari detected. Full-screen functionality disabled.");
    }
  };

  const history = useNavigate();
  const logoutFunction = () => {
    console.log("hello");

    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    localStorage.removeItem("remember-admin");
    localStorage.removeItem("remember-user");

    if (isUserType === "superadmin" || isUserType === "admin") {
      history("/Admin/login");
    }

    if (isUserType === "user") {
      history("/login");
    }
  };
  const [sideBarOn, setSideBarOn] = useState(true);
  const handleSideBar = () => {
    setSideBarOn(!sideBarOn);
  };
  const gridStyles = {
    display: "grid",
    gridTemplateColumns: sideBarOn ? "80px 1fr" : "0px 1fr",
  };

  const [isUserType, setIsUserType] = useState();
  useEffect(() => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      setIsUserType(userData.type);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }, [isUserType]);

  return (
    <div
      className="duration-300 bg-[#e4e3e3] h-dvh max-h-screen w-screen"
      style={gridStyles}
    >
      {/* SideBar */}
      <div
        className={`h-dvh bg-[#f1f1f1] shadow-md duration-300 ${
          sideBarOn ? "" : " -translate-x-20 "
        }`}
      >
        <div className="h-10 px-3 py-1 font-semibold ">
          {/* <LinkToTag isUserType={isUserType} /> */}

          {/* this is main code of button start*/}
          <LinkToTag
            className="cursor-pointer transition transform duration-150 "
            title="Account"
            placement="right"
            route="account"
          >
            <PersonIcon />
          </LinkToTag>

          {/* this is main code of button end */}
        </div>
        <div className="h-[calc(100%-80px)] text-justify px-3 py-6 text-base font-medium">
          <ul className="leading-10">
            <li className="my-2">
              <LinkToTag
                className="cursor-pointer transition transform duration-150 "
                title="Dashboard"
                placement="right"
                route="dashboard"
              >
                <DashboardRoundedIcon />
              </LinkToTag>
            </li>
            <div>
              <li className="my-2">
                <LinkToTag
                  className="cursor-pointer transition transform duration-150"
                  title="Booking"
                  placement="right"
                  route="booking"
                >
                  <BeenhereRoundedIcon />
                </LinkToTag>
              </li>

              <li className="my-2">
                <LinkToTag
                  className="cursor-pointer transition transform duration-150"
                  title="Trip"
                  placement="right"
                  route="trip"
                >
                  <TimeToLeaveIcon />
                </LinkToTag>
              </li>
            </div>

            <li className="my-2">
              <LinkToTag
                className="cursor-pointer transition transform duration-150"
                title="salary"
                placement="right"
                route="salary"
              >
                <PaymentIcon />
              </LinkToTag>
            </li>
            <li className="my-2">
              <LinkToTag
                className="cursor-pointer transition transform duration-150"
                title="commission"
                placement="right"
                route="commission"
              >
                <PaidIcon />
              </LinkToTag>
            </li>

            {isUserType === "superadmin" && (
              <div>
                <li className="my-2">
                  <LinkToTag
                    className="cursor-pointer transition transform duration-150"
                    title="Admin"
                    placement="right"
                    route="admin"
                  >
                    <AccountBox />
                  </LinkToTag>
                </li>
              </div>
            )}
            {/* {isUserType === "admin" ? (
              <div>
                <div>
                  {" "}
                  <li className="my-2">
                    <LinkToTag
                      className="cursor-pointer transition transform duration-150 "
                      title="Salary"
                      placement="right"
                      route="salary"
                    >
                      <PaymentIcon />
                    </LinkToTag>
                  </li>
                </div>
                <div>
                  {" "}
                  <li className="my-2">
                    <LinkToTag
                      className="cursor-pointer transition transform duration-150 "
                      title="Commission"
                      placement="right"
                      route="commission"
                    >
                      <PaidIcon />
                    </LinkToTag>
                  </li>
                </div>
              </div>
            ) : null} */}
            {isUserType === "superadmin" || isUserType === "admin" ? (
              <div>
                <li className="my-2">
                  <LinkToTag
                    className="cursor-pointer transition transform duration-150"
                    title="Payment"
                    placement="right"
                    route="payment"
                  >
                    <PaymentsIcon />
                  </LinkToTag>
                </li>
                <li className="my-2">
                  <LinkToTag
                    className="cursor-pointer transition transform duration-150 "
                    title="Employee"
                    placement="right"
                    route="employee"
                  >
                    <ManIcon />
                  </LinkToTag>
                </li>
              </div>
            ) : null}
          </ul>
        </div>
        <div className="text-white  bg-orange-500 shadow-md hover:bg-orange-600 px-4">
          <Link to="/login">
            <Tooltip title="Logout" arrow placement="right">
              <IconButton onClick={logoutFunction}>
                <ExitToAppRoundedIcon
                  sx={{ color: "white" }}
                ></ExitToAppRoundedIcon>
              </IconButton>
            </Tooltip>
          </Link>
        </div>
      </div>

      {/* Navbar */}
      <div className="h-dvh transition-all max-w-full w-full">
        <div
          className={`h-10 bg-[#f1f1f1] shadow-md flex justify-between transition-all duration-300 w-full`}
        >
          <div className="h-10 w-10 mx-1" >
            <Tooltip placement="bottom" arrow title="Menu">
              <IconButton onClick={handleSideBar} >
                <List className={" mx-2 hover:fill cursor-pointer"} />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            <Tooltip placement="bottom" arrow title="Full screen">
              <IconButton onClick={() => handleFullscreen()}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="black"
                  className="bi bi-arrows-fullscreen float-right mx-3 cursor-pointer"
                  viewBox="0 0 16 16"
                >
                  {fullscreen ? (
                    <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5m5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5M0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5m10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0z" />
                  ) : (
                    <path
                      fillRule="evenodd"
                      d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707m-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707"
                    />
                  )}
                </svg>
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/* Render children here */}
        <div
          className={`duration-300 max-h-[calc(100vh-40px)] overflow-y-auto ${
            sideBarOn ? "max-w-[calc(100vw-80px)]" : "max-w-[calc(100vw)]"
          }`}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default UserSidebarNavbar;
