import { useState, useEffect } from "react";
import UpdateTrip from "./updateTrip";
import UserSidebarNavbar from "../User/UserSidebarNavbar";
import Table from "../../Components/Table/Table";
import Search from "../../Components/Search/Search";
import Token from "../../Database/Token";
import AddTrip from "./AddTrip";
import StatusTrip from "../../Components/Pages/Admin/StatusTrip";

import Loading from "../../Assets/SVG/Loading";
import Clipboard from "../../Assets/SVG/Clipboard";

import Th from "../../Components/Table/Th";
import StatusPlan from "../../Components/Others/StatusPlan";
import TripBtn from "./TripBtn";
import { useAppContext } from "../../Database/Context";
import { addNotification } from "../../Components/Notification/Notification";
import { Tooltip } from "@mui/material";

export default function AdminTrip(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();

  const [id, setId] = useState(false);
  const [name, setName] = useState({});
  const [addTrip, setAddTrip] = useState(false);

  const { sideBarOn } = props;
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/plan/Admin/showPlan?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}`
        );
        console.log("haslaslgn lkangln alfgnlafng l;kjanf");
        console.log(response);
        if (response.data.status) {
          setTabalData(response.data.data);
          console.log(TableData);
          setData(Object.values(response.data.data.data));
        } else {
          addNotification("error", "message", "error");
        }
      } catch (e) {
        console.log(e);
        addNotification("error", e.message, "danger");
      }
      setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar sideBarOn={sideBarOn}>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>
          <Table
            data={data}
            button={
              <div key={addTrip}>
                <AddTrip
                  update={() => setUpdate(!update)}
                  btnTitle="Add Trip"
                  baseAPI="/plan/all/createPlan"
                  id={id}
                  name={name}
                  setId={(e) => setId(e)}
                />
              </div>
            }
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                <Th
                  title="id"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  id
                </Th>
                <Th
                  title="name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Name
                </Th>
                <Th
                  title="email"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Max person
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Total person
                </Th>
                <Th
                  title="gender"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  departure date
                </Th>
                <Th
                  title="dob"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  arrival date
                </Th>
                <Th
                  title="status"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  status
                </Th>
                <th
                  scope="col"
                  colSpan={1}
                  className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                >
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={11}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.map((list) => (
                <tr
                  className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                  key={list.id}
                >
                  <td className="w-4 px-2">{list.id}</td>
                  <th className="py-4 px-2 font-medium" scope="row">
                    {list.name}
                  </th>
                  <td className="px-2 py-4">{list.total_person}</td>
                  <td className="px-2 py-4">{list.bookings_count}</td>
                  <td className="px-2 py-4">{list.formatted_departure_date}</td>
                  <td className="px-2 py-4">{list.formatted_arrival_date}</td>
                  <td className="px-2 py-4">
                    <StatusPlan status={list.status} />
                  </td>
                  <td className="px-2 py-4 grid grid-cols-[40px_40px_40px_40px] gap-1">
                    {loading !== list.id &&
                      list.status !== "completed" &&
                      list.status !== "cancelled" && (
                        <StatusTrip
                          id={list.id}
                          onloading={(e) => setLoading(e)}
                          onUpdate={() => setUpdate(!update)}
                        />
                      )}
                    <TripBtn
                      baseApi="/booking/Admin/getBookingsByPlan"
                      id={list.id}
                    />
                    {/* {list.status === "rejected" && (
                      <div className="">No Action</div>
                    )} */}
                    {loading === list.id && (
                      <div className="w-16 flex items-center justify-center">
                        <Loading />
                      </div>
                    )}

                    <Tooltip arrow placement="top" title="Copy">
                      <button
                        className="w-10 h-10 bg-green-500 rounded-md flex items-center justify-center outline-none focus-visible:ring-2 ring-offset-2 ring-green-500"
                        onClick={() => {
                          setName({
                            arrival_date: list.arrival_date,
                            booking_commission: list.booking_commission,
                            bookings_count: list.bookings_count,
                            created_by: list.created_by,
                            departure_date: list.departure_date,
                            deposit_amount: list.deposit_amount,
                            id: list.id,
                            name: list.name,
                            remaining_amount: list.remaining_amount,
                            remark: list.remark,
                            status: list.status,
                            total_person: list.total_person,
                          });
                          setId(list.id);
                          setAddTrip(!addTrip);
                        }}
                      >
                        <Clipboard className="fill-white" id={list.id} />
                      </button>
                    </Tooltip>
                    {list.status !== "completed" &&
                      list.status !== "cancelled" && (
                        <UpdateTrip
                          baseApi="/plan/Admin/updatePlan"
                          id={list.id}
                          name={list.name}
                          total_person={list.total_person}
                          dept_date={list.departure_date}
                          arr_date={list.arrival_date}
                          rem_amt={list.remaining_amount}
                          remark={list.remark}
                          book_com={list.booking_commission}
                          // inst_com={list.booking_commission}
                          dept_amt={list.deposit_amount}
                          update={() => setUpdate(!update)}
                        />
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
