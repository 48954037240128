import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { Fragment } from "react";
import UserLogin from "./Pages/User/UserLogin";
import UserHome from "./Pages/User/UserHome";
import UserBooking from "./Pages/User/UserBooking";
import UserTrip from "./Pages/User/UserTrip"
import UserSalary from "./Pages/User/UserSalary"
import UserCommission from "./Pages/User/userCommission";
import UserAccount from "./Pages/User/UserAccount";

import AdminLogin from "./Pages/Admin/AdminLogin";
import AdminHome from "./Pages/Admin/AdminHome";
import AdminAccount from "./Pages/Admin/AdminAccount";
import AdminBooking from "./Pages/Admin/AdminBooking";
import AdminPayment from "./Pages/Admin/AdminPayment";
import AdminEmployee from "./Pages/Admin/AdminEmployee";
import AdminTrip from "./Pages/Admin/AdminTrip";
import Admin from "./Pages/Admin/Admin";
import AdminSalary from "./Pages/Admin/AdminSalary"
import AdminCommission from "./Pages/Admin/AdminCommission"

// import InstAccount from "./Pages/Instructor/InstAccount";
// import InstHome from "./Pages/Instructor/InstHome";
// import InstTrip from "./Pages/Instructor/InstTrip";
// import InstPayment from "./Pages/Instructor/Instpayment";

import NotFound from "./Pages/Others/NotFound";
import ForgotPassword from "./Pages/Others/ForgotPassword";
import ForgotPasswordMain from "./Pages/Others/ForgotPasswordMain";

function App() {
  return (
    <Fragment>
      <ReactNotifications />
      <BrowserRouter>
        <Routes>
          {/* Admin Routes */}
          <Route exact path="/Admin/login" element={<AdminLogin />} />
          <Route exact path="/Admin/dashboard" element={<AdminHome />} />
          <Route exact path="/Admin/booking" element={<AdminBooking />} />
          <Route exact path="/Admin/payment" element={<AdminPayment />} />
          <Route exact path="/Admin/account" element={<AdminAccount />} />
          <Route exact path="/Admin/employee" element={<AdminEmployee />} />
          <Route exact path="/Admin/trip" element={<AdminTrip />} />
          <Route exact path="/Admin/admin" element={<Admin />} />
          <Route exact path="/Admin/salary" element={<AdminSalary />} />
          <Route exact path="/Admin/commission" element={<AdminCommission />} />
          <Route exact path="/forgot-request" element={<ForgotPassword />} />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPasswordMain />}
          />
          {/* <Route exact path="/userDemo1" element={<UserDemo1 />} />  */}

          {/* User routes */}
          <Route exact path="/" element={<UserLogin />} />
          <Route exact path="/login" element={<UserLogin />} />
          <Route exact path="/dashboard" element={<UserHome />} />
          <Route exact path="/booking" element={<UserBooking />} />
          <Route exact path="/trip" element={<UserTrip />} />
          <Route exact path="/salary" element={<UserSalary />} />
          <Route exact path="/commission" element={<UserCommission />} />
          <Route exact path="/account" element={<UserAccount />} />
          {/* <Route exact path="/userDemo1" element={<UserDemo1 />} />  */}

          {/* Instructor routes */}
          {/* <Route exact path="/Inst/dashboard" element={<InstHome />} />
          <Route exact path="/Inst/trip" element={<InstTrip />} />
          <Route exact path="/Inst/account" element={<InstAccount />} />
          <Route exact path="/Inst/payment" element={<InstPayment />} /> */}

          {/* Others */}
          <Route path="/*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
