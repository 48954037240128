import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { IconButton, Tooltip } from "@mui/material";

export default function SuperBtn(props) {
  const [active, setActive] = useState(false);
  const statusChange = async (title) => {
    setActive(false);
    var res;
    var response;
    try {
      res = await Token.get(
        `/user/Admin/status?id=${props.id}&status=${title}`
      );
      response = await Token.get(
        `/user/SuperAdmin/changeStatus?id=${props.id}&status=${title}`
      );

      console.log("Response : ", response);
      console.log("Response : ", res);
      if (response.status === 200 || res.status === 200) {
        addNotification(
          "Successful booking",
          "Your booking has been Updated",
          "success"
        );
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (error) {
      addNotification("Failed", error.message, "error");
    }
    props.update();
  };

  return (
    <Fragment>
      <div className="text-white bg-indigo-500 p-2 rounded-md shadow-md hover:bg-indigo-600 ">
        <Tooltip arrow placement="top" title="Super">
          <IconButton
            className="w-6 h-6 outline-none"
            onClick={() => setActive(!active)}
          >
            <EmojiPeopleIcon className="text-white " />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          active ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`max-w-[450px] w-[450px] bg-white rounded-md shadow-xl p-5 transition-all ${
            active ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <p className="my-3 text-lg">
            Do you want to change status of booking ?
          </p>
          <button
            className="w-full h-12 bg-green-500 hover:bg-green-600 text-white text-xl rounded-md shadow-md"
            onClick={() => statusChange("activated")}
          >
            Activated
          </button>
          <button
            className="mt-3 w-full h-12 bg-red-500 hover:bg-red-600 text-white text-xl rounded-md shadow-md"
            onClick={() => statusChange("deactivated")}
          >
            Deactivated
          </button>
          <button
            className="w-full h-12 text-red-500 border-[1.5px] border-red-500 border-dotted my-3 rounded-md shadow-md hover:bg-red-50"
            onClick={() => setActive(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
