import { useState, Fragment } from "react";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../Components/Others/Button";

export default function DeleteAdmin(props) {
  const [employee, setEmployee] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteBooking = async () => {
    setLoading(true);
    const jsonData = {
        id : props.id,
    }
    try {
      const response = await Token.get(`${props.baseAPI}?id=${props.id}`);
      console.log("Response:", response);

      if (response.data.status) {
        addNotification(
          "record deleted successfully",
          "Delete",
          "success"
        );
        setEmployee(false)
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Fragment>
      <div className="h-10 w-10 rounded-md shadow-md text-white hover:bg-red-600 bg-red-500 outline-none focus-visible:ring-2 ring-offset-2 ring-red-500">
        <Tooltip arrow placement="top" title="Details">
          <IconButton className="" onClick={() => setEmployee(true)}>
            <DeleteIcon className="text-white mx-auto my-auto" />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          employee ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            employee ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >

          <h1 className="text-2xl font-bold text-red-500">Warning</h1>
          <p className="my-3 text-lg">Are you sure you want to delete?</p>
          <Button
            color="red"
            title="Delete"
            onClick={() => deleteBooking()}
          />
          <button
            className="w-full h-10 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setEmployee(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
