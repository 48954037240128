import Image from "../../Assets/Images/Logo/Logo.png";
import Axios from "../../Database/Axios";

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { addNotification } from "../../Components/Notification/Notification";

import Eye from "../../Assets/SVG/Eye";
import EyeSlash from "../../Assets/SVG/EyeSlash";
import Spin from "../../Assets/SVG/Spin";

export default function UserLogin() {
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [eye, setEye] = useState(true);
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const info = localStorage.getItem("remember-user");
    if (info) {
      history("/dashboard");
    }
  }, []);
  function isValidEmailFunction(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const loginFunction = async () => {
    if (email === "" && !isValidEmailFunction(email)) {
      addNotification(
        "fild is empty",
        "you need to enter valid email address",
        "danger"
      );
      return 0;
    }
    if (password === "") {
      addNotification(
        "fild is empty",
        "you need to enter password address",
        "danger"
      );
      return 0;
    }

    const data = {
      email: email,
      password: password,
    };
    try {
      setLoading(true);
      const response = await Axios.post("/user/user/login", data);
      console.log(response.data);
      setLoading(false);
      if (response.data.status) {
        addNotification(
          `welcome ${response.data.data.name}`,
          "you scessfully login to your account",
          "success"
        );
        localStorage.setItem("accessToken", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.data));

        if (remember) {
          localStorage.setItem("remember-user", true);
        }

        history("/dashboard");
      } else {
        addNotification("wrong", response.data.message, "danger");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
      addNotification("Error", e.message, "danger");
    }
  };
  return (
    <div className="flex items-center justify-center bg-[#e4e3e3] h-dvh p-3">
      <div className="p-5 max-w-[400px] w-[400px] min-w-[200px] bg-white shadow-md rounded-md">
        <div className="flex justify-center ">
          <img
            src={Image}
            alt="not found"
            className="w-[200px] h-[113.594px] "
          />
        </div>
        <label>Email</label>
        <input
          type="email"
          className="w-full h-10  rounded-sm border-orange-300 border-[1.5px] outline-none px-2 focus-within:border-orange-500"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Enter email"
        />
        <div className="h-2"></div>
        <label>Password</label>
        <div className="group">
          <input
            type={eye ? "password" : "text"}
            className="w-[calc(100%-40px)] h-10 rounded-s-sm border-orange-300 border-[1.5px] outline-none px-2 focus-within:border-orange-500 group-focus:border-orange-500 float-left border-r-0"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="Enter password"
          />
          <div
            className="float-left w-10 h-10 border-[1.5px] border-l-0 border-orange-300 flex items-center justify-center hover:border-orange-500 hover:border-l-[1.5px] group-focus:border-orange-500 rounded-e-sm"
            onClick={() => setEye(!eye)}
          >
            {!eye && <Eye />}
            {eye && <EyeSlash />}
          </div>
        </div>
        <div className="h-2 "></div>
        <div className="mt-1 w-full">
          <input
            type="checkbox"
            placeholder=""
            className="h-4 accent-orange-500 w-4 float-left my-1 text-white"
            onClick={() => setRemember(!remember)}
          />
          <p className="float-left ml-1 h-5">Remember me</p>{" "}
          <p
            className="float-right cursor-pointer hover:text-gray-600"
            onClick={() => history("/forgot-request")}
          >
            Lost your password?
          </p>
        </div>
        <div className="h-2"></div>
        {loading && <br />}
        {!loading && (
          <button
            className="h-9 bg-orange-500 w-full text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 my-2 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-600"
            onClick={loginFunction}
          >
            Login
          </button>
        )}
        {loading && (
          <button className="h-9 bg-orange-500 w-full text-white rounded-sm outline-none focus-within:bg-orange-600 hover:bg-orange-600 flex items-center justify-center my-2">
            <Spin />
          </button>
        )}
        <div className="inline-flex items-center justify-center w-full">
          <hr className="w-64 h-px my-5 bg-gray-200 border-0 dark:bg-gray-700" />
          <span className="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 ">
            and
          </span>
        </div>

        {/* This is Button for Powered by Zokasta.com */}

        <button className="flex items-center bg-white border w-full border-gray-300 rounded-lg shadow-md max-w-full px-6 py-2 text-sm font-medium text-gray-800 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          <a href="https://zokasta.com" target="_blank">Powered by Zokasta.com</a>
        </button>
     
      </div>
    </div>
  );
}
