export default function StatusEmpoyee(props) {
    return (
      <span
        className={`${
          props.status === "activated"
            ? "bg-green-700 text-green-300"
            : props.status === "deactivated"
            ? "bg-red-600 text-red-100"
            : "bg-yellow-600 text-yellow-100"
        } text-xs font-medium me-2 px-2.5 py-1 uppercase cursor-pointer rounded`}
      >
        {props.status}
      </span>
    );
  }
  