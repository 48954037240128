import React, { useState, useEffect } from "react";
import { useAppContext } from "../../Database/Context";
import UserSidebarNavbar from "../User/UserSidebarNavbar";
import Token from "../../Database/Token";
import { addNotification } from "../../Components/Notification/Notification";
import Th from "../../Components/Table/Th";
import Search from "../../Components/Search/Search";
import Table from "../../Components/Table/Table";
import AddUser from "./AddUser";
import Loading from "../../Assets/SVG/Loading";
import StatusEmployee from "../../Components/Others/StatusEmployee";
import EmployeeStatus from "./EmployeeStatus";
import UpdateEmployeeStatus from "./updateEmployeeStatus";
import GiveSalary from "./giveSalary";
import DeleteAdmin from "./deleteAdmin";

export default function AdminEmployee() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/user/Admin/showUserByAdmin?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}&type=user`
        );
        console.log(response);
        if (response.data.status) {
          setTabalData(response.data.data);
          console.log(TableData);
          setData(Object.values(response.data.data.data));
        } else {
          console.log(response)
        }
      } catch (e) {
        console.log(e);
        addNotification("error", e.message, "danger");
      }
      setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>
          <Table
            data={data}
            button={
              <AddUser
                update={() => setUpdate(!update)}
                btnTitle1="New User"
                baseAPI="/user/Admin/createAccount"
                chkType="user"
              />
            }
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                <Th
                  title="id"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  id
                </Th>
                <Th
                  title="name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Name
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  phone
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Total Bookings
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Total Bookings Amount
                </Th>
                <Th
                  title="phone"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Total Discount
                </Th>
                <Th
                  title="dob"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Left Commission
                </Th>
                <Th
                  title="status"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  status
                </Th>
                <th
                  scope="col"
                  colSpan={1}
                  className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                >
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={9}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.map((list) => (
                <tr
                  className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                  key={list.id}
                >
                  <td className="w-4 px-2">{list.id}</td>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    {list.name}
                  </th>
                  {/* <td className="px-4 md:px-4 sm:px-2 py-4">{list.email}</td> */}
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.phone}</td>

                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.booking_count}
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.total_amount}
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.total_discount}
                  </td>
                  {/* <td className="px-4 md:px-4 sm:px-2 py-4">{list.dob}</td>  */}

                  {/* <td className="px-4 md:px-4 sm:px-2 py-4">{list.booking_count}</td> */}
                  {/* <td className="px-4 md:px-4 sm:px-2 py-4">{list.total_amount}</td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.total_discount}</td> */}
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.total_commission_left}
                  </td>

                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    <StatusEmployee status={list.status} />
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4 grid grid-col-3 grid-cols-[40px_40px_40px_40px] gap-1">
                    {loading !== list.id && list.status !== "rejected" && (
                      <EmployeeStatus
                        id={list.id}
                        onloading={(e) => setLoading(e)}
                        onUpdate={() => setUpdate(!update)}
                      />
                    )}
                    <UpdateEmployeeStatus
                      baseAPI="/user/Admin/updateAccountByAdmin"
                      id={list.id}
                      name={list.name}
                      email={list.email}
                      phone={list.phone}
                      update={() => setUpdate(!update)}
                    />
                    <GiveSalary
                      baseAPI="/salary/Admin/createSalary"
                      id={list.id}
                    />
                     <DeleteAdmin
                      baseAPI="/user/Admin/deleteAccount"
                      id={list.id}
                    />
                    {list.status === "rejected" && (
                      <div className="">No Action</div>
                    )}
                    {loading === list.id && (
                      <div className="w-16 flex items-center justify-center">
                        <Loading />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
