import { useState, useEffect } from "react";

import UserSidebarNavbar from "../User/UserSidebarNavbar";
import Table from "../../Components/Table/Table";
import Search from "../../Components/Search/Search";
import Token from "../../Database/Token";
import Loading from "../../Assets/SVG/Loading";
import Th from "../../Components/Table/Th";
import Status from "../../Components/Others/Status";

import { useAppContext } from "../../Database/Context";
import { addNotification } from "../../Components/Notification/Notification";
import StatusChange from "../Admin/StatusChange";
import GiveSalary from "./giveSalary";

export default function UserSalary(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("asc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();

  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/salary/Admin/showSalary?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}`
        );
        console.log(response);
        if (response.data.status) {
          setTabalData(response.data.data);
          console.log(TableData);
          setData(Object.values(response.data.data.data));
        } else {
          addNotification("error", "message", "error");
        }
      } catch (e) {
        console.log(e);
        addNotification("error", e.message, "danger");
      }
      setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div className="">
          <div key={TableData}>
            <Table
              button={""}
              updateRow={(e) => {
                setRow(e);
                setPage(1);
              }}
              cPage={TableData.current_page}
              updatePage={(e) => setPage(e)}
              lPage={TableData.last_page}
              data={data}
            >
              <thead className="text-xs text-gray-100 uppercase bg-orange-500">
                <tr>
                  <Th
                    title="id"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    id
                  </Th>
                  <Th
                    title="users.name"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    Employee
                  </Th>
                  <Th
                    title="salary_date"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    Salary Date
                  </Th>
                  <Th
                    title="amount"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    Amount
                  </Th>
                  <Th
                    title="status_user"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    status by user
                  </Th>
                  <Th
                    title="status_admin"
                    setFunction={(e, t) => {
                      setOrder(e);
                      setTitle(t);
                    }}
                  >
                    status by admin
                  </Th>
                  <th
                    scope="col"
                    colSpan={1}
                    className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                  >
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr>
                    <td
                      colSpan={9}
                      className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                    >
                      Data not found
                    </td>
                  </tr>
                )}
                {data.map((list) => (
                  <tr
                    className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                    key={list.id}
                  >
                    <td className="w-4 px-2">{list.id}</td>
                    <th
                      className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                      scope="row"
                    >
                      {list.user_name}
                    </th>
                    <td className="px-4 md:px-4 sm:px-2 py-4">
                      {list.formatted_salary_date}
                    </td>
                    <td className="px-4 md:px-4 sm:px-2 py-4">{list.amount}</td>
                    <td className="px-4 md:px-4 sm:px-2 py-4">
                      {" "}
                      <Status status={list.status_user} />
                    </td>
                    <td className="px-4 md:px-4 sm:px-2 py-4">
                      {" "}
                      <Status status={list.status_admin} />
                    </td>
                    <td className="px-4 md:px-4 sm:px-2 py-4 grid grid-col-2 grid-cols-[40px_40px] gap-4">
                      {loading !== list.id && list.status !== "rejected" && (
                        <StatusChange
                          api="/salary/all/updateStatus"
                          id={list.id}
                          onloading={(e) => setLoading(e)}
                          onUpdate={() => setUpdate(!update)}
                        />
                      )}
                      
                      {list.status === "rejected" && (
                        <div className="">No Action</div>
                      )}
                      {loading === list.id && <Loading />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
