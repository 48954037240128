import { useState, Fragment } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { addNotification } from "../../Components/Notification/Notification";

import Token from "../../Database/Token";
import Spin from "../../Assets/SVG/Spin";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

export default function GiveSalary(props) {
  const [employee, setEmployee] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState();
  const [date, setDate] = useState();

  const AddBookingFunction = async () => {
    setLoading(true);
    try {
      const response = await Token.get(
        `${props.baseAPI}?id=${props.id}&amount=${amount}&date=${date}`
      );
      if (response.data.status) {
        addNotification(
          "Successful booking",
          "Your booking has been updated",
          "success"
        );
      } else {
        addNotification("Error", response.data.message, "danger");
        console.log("Error message from server:", response.data.message);
      }
    } catch (e) {
      console.error("Error during API call:", e);
      addNotification("Error", e.message, "danger");
    } finally {
      setLoading(false);
    }
    setEmployee(false);
  };

  const allEmployee = async () => {
    if (!amount) {
      addNotification("Error", "Please enter Name", "danger");
      return;
    }

    AddBookingFunction();
  };

  return (
    <Fragment>
      <div className="text-white bg-green-400 p-2 rounded-md shadow-md hover:bg-green-500 ">
        <Tooltip arrow placement="top" title="Salary">
          <IconButton
            className="w-6 h-6 outline-none"
            onClick={() => setEmployee(true)}
          >
            <CurrencyRupeeIcon className="text-white" />
          </IconButton>
        </Tooltip>
      </div>

      <div
        className={`z-[5] w-screen h-screen fixed inset-0 flex items-center justify-center p-3 ${
          employee ? "visible" : "invisible"
        } bg-black/50`}
      >
        <div
          className={`bg-white max-w[400px] w-[400px] rounded-md shadow-2xl transition-all p-3 ${
            employee ? "scale-100 opacity-100" : "scale-75 opacity-0"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* <Scroll height="500px" /> */}
          <p className="text-center text-4xl text-bold my-1 text-orange-500 font-semibold">
            Update Commission Details
          </p>
          
          {/* </input> */}
          <p className="mt-1">Amount</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            type="number"
            placeholder="Enter amount"
          />
          <p className="mt-1">Date</p>
          <input
            className="w-full h-10 border-[1px] rounded-sm border-[#b3b3b3] focus:border-blue-600 outline-none p-2 focus:border-[1.7px] transition-all"
            onChange={(e) => setDate(e.target.value)}
            value={date}
            type="date"
            placeholder="Enter date"
          />
          {!loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600"
              onClick={allEmployee}
            >
              Confirm
            </button>
          )}
          {loading && (
            <button
              className="w-full sm:w-[275px] sm:mx-5 h-10 rounded-sm my-2 text-lg bg-orange-500 shadow-md text-white outline-none focus-visible:ring-2 focus:bg-orange-600 hover:bg-orange-600 transition-all focus-visible:ring-offset-2 focus-visible:ring-orange-600 flex items-center justify-center"
              disabled
            >
              <Spin />
            </button>
          )}
          <button
            className="w-full h-10 sm:w-[275px] sm:mx-5 rounded-sm my-2 text-lg bg-white border-[1.5px] border-dashed border-orange-600 text-orange-500 shadow-md outline-none hover:bg-orange-50 focus:bg-orange-50"
            onClick={() => setEmployee(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Fragment>
  );
}
