import React, { useEffect, useState } from "react";
import { addNotification } from "../../Components/Notification/Notification";
import UserSidebarNavbar from "../User/UserSidebarNavbar";
import Token from "../../Database/Token";
import Loading from "../../Assets/SVG/Loading";

const UserAccount = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    setName(data.name);
    setEmail(data.email);
    setPhone(data.phone);
    setDob(data.birthday);
  }, []);

  const callFunction = async () => {
    setLoading(true)
    const jsonData = {
      name: name,
      email: email,
      phone: phone,
    };
    try {
      const response = await Token.post("/user/all/updateAccount", jsonData);
      console.log(response);
      if (response.data.status) {
        localStorage.removeItem("user");
        const data = JSON.stringify(response.data.data);
        localStorage.setItem("user", data);
        addNotification("Updated", "Your profile has been updated", "success");
      }
    } catch (e) {
      addNotification("error", e.message, "danger");
    }
    setLoading(false)
  };
  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100%-20px)] w-full p-4">
        <div className="w-full bg-white shadow-md rounded-md p-6 pt-10">
          <h1 className="text-3xl font-bold h-16">
            Welcome <p className="text-orange-500">{name}</p>
          </h1>
          <p className="font-bold mt-3 mb-1 ">Personal Information</p>
          <div className="w-full h-[0.5px] opacity-40 bg-gray-600 shadow-md"></div>

          <div className="grid grid-cols-2 sm:grid-cols-1 max-w-full min-w-0 gap-4">
            <div>
              <p className="font-bold mt-2">Name</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <p className="font-bold mt-2">Email</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <p className="font-bold mt-2">Phone Number</p>
              <input
                type="text"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <p className="font-bold mt-2">DOB</p>
              <input
                type="date"
                className="w-full h-10 bg-[#f4f6f8] border-[1px] border-gray-300 rounded-sm outline-none focus:border-orange-500 px-2 focus:border-[1.75px]"
                value={dob}
              />
            </div>
          </div>
          {!loading && (
            <button
              className="py-2 mt-4 rounded-md text-white text-xl font-bold px-10 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500"
              onClick={callFunction}
            >
              Save
            </button>
          )}
          {loading && (
            <button className="py-2 mt-4 rounded-md text-white text-xl font-bold px-12 bg-orange-500 shadow-md hover:bg-orange-600 focus-visible:ring-2 focus-visible:ring-offset-2 ring-orange-500 outline-none focus-visible:bg-orange-500">
              <Loading />
            </button>
          )}
        </div>
      </div>
    </UserSidebarNavbar>
  );
};

export default UserAccount;
