import { useState, useEffect } from "react";

import UserSidebarNavbar from "../User/UserSidebarNavbar";
import Table from "../../Components/Table/Table";
import Search from "../../Components/Search/Search";
import Token from "../../Database/Token";
// import Rejecte from "../../Components/Pages/User/Rejecte";
import Loading from "../../Assets/SVG/Loading";
import Th from "../../Components/Table/Th";
import Status from "../../Components/Others/Status";
import StatusChange from "./StatusChange";
import { useAppContext } from "../../Database/Context";
import { addNotification } from "../../Components/Notification/Notification";
// import AddPayment from "./AddPayment";

export default function AdminPayment(props) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [row, setRow] = useState(5);
  const [order, setOrder] = useState("desc");
  const [title, setTitle] = useState("id");
  const [page, setPage] = useState(1);

  const [TableData, setTabalData] = useState([]);

  const { dispatch } = useAppContext();
  useEffect(() => {
    dispatch({ type: "SET_LOADING", payload: true });
    const functionCall = async () => {
      try {
        const response = await Token.get(
          `/payment/Admin/show?row=${row}&search=${search}&title=${title}&order=${order}&page=${page}`
        );
        console.log(response.data);
        if (response.data.status) {
          setTabalData(response.data.data);
          setData(Object.values(response.data.data.data));
        } else {
          addNotification("error", "message", "error");
        }
      } catch (e) {
        console.log(e);
        addNotification("error", e.message, "danger");
      }
      setLoading(false);
      dispatch({ type: "SET_LOADING", payload: false });
    };
    functionCall();
  }, [update, row, search, order, page]);

  
  const searchChange = (e) => {
    setSearch(e);
    setUpdate(!update);
  };

  return (
    <UserSidebarNavbar>
      <div className="h-[calc(100dvh-40px)] w-full p-4 overflow-y-auto">
        <Search className="mb-4" onSearch={searchChange}></Search>
        <div key={TableData}>
          <Table
            updateRow={(e) => {
              setRow(e);
              setPage(1);
            }}
            data={data}
            cPage={TableData.current_page}
            updatePage={(e) => setPage(e)}
            lPage={TableData.last_page}
          >
            <thead className="text-xs text-gray-100 uppercase bg-orange-500">
              <tr>
                <Th
                  title="id"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  id
                </Th>
                <Th
                  title="Receiver Name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Receiver Name
                </Th>
                <Th
                  title="Payment ID"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Amount
                </Th>
                <Th
                  title="Payment ID"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Payment ID
                </Th>
                <Th
                  title="Type"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Type
                </Th>
                <Th
                  title="Method"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Method
                </Th>
                <Th
                  title="Sender Name"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  Sender Name
                </Th>
                <Th
                  title="status"
                  setFunction={(e, t) => {
                    setOrder(e);
                    setTitle(t);
                  }}
                >
                  status
                </Th>
                <th
                  scope="col"
                  colSpan={1}
                  className="duration-300 pl-2 sm:pl-1 py-2 group h-14 min-w-[75px]"
                >
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length === 0 && (
                <tr>
                  <td
                    colSpan={9}
                    className="text-center text-bold text-xl text-gray-800 uppercase py-4 border-[1.5px] border-gray-300"
                  >
                    Data not found
                  </td>
                </tr>
              )}
              {data.map((list) => (
                <tr
                  className="pl-2 bg-white text-slate-900 hover:bg-gray-200 border-y-[1.7px]"
                  key={list.id}
                >
                  <td className="w-4 px-2">{list.id}</td>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    {list.reciver_name}
                  </th>
                  <th
                    className="px-4 py-4 md:px-4 sm:px-2 font-medium"
                    scope="row"
                  >
                    {list.amount}
                  </th>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.online_id}
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.type}</td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">{list.method}</td>
                  
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {list.user_name}
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    <Status status={list.status} />
                  </td>
                  <td className="px-4 md:px-4 sm:px-2 py-4">
                    {loading !== list.id && list.status === "none" && (
                      <StatusChange
                        api="/payment/user/changeStatusPayment"
                        id={list.id}
                        onloading={(e) => setLoading(e)}
                        onUpdate={() => setUpdate(!update)}
                      />
                    )}
                    {list.status !== "none" && (
                      <div className="">No Action</div>
                    )}
                    {loading === list.id && (
                      <div className="w-16 flex items-center justify-center">
                        <Loading />
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </UserSidebarNavbar>
  );
}
