export default function Button({color,title="Title",onClick}) {
  return (
    <button
      className={`outline-none my-[3px] w-full h-12 bg-${color}-500 hover:bg-${color}-600 focus-visible:ring-2 ring-offset-2 ring-${color}-500 text-white text-xl rounded-md shadow-md`}
      onClick={()=>onClick()}
    >
      {title}
    </button>
  );
}
